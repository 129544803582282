import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowIcon, ArrowLineIcon } from "../../components/Icons";
import { Section, SectionTitle } from "../../components/Section";
import LeftBg from "../../images/left.png";
import RightBg from "../../images/right.png";
import BreakpointDown from "../../components/Media/BreakpointDown";
const SectionBorderTop = styled(Section)`
  border-top: 1px #d2d8e0 solid;
  overflow-x: hidden;
  .container {
    z-index: 2;
  }
  &:before {
    left: -20px;
    ${BreakpointDown.xl`
       display:none;
    `}
  }
  &:after {
    right: -15px;
    ${BreakpointDown.xl`
       display:none;
    `}
  }
`;
const SlickSliderInner = styled.div`
  position: relative;
  box-shadow: 0px 10px 50px #0000000f;
  border: 1px solid #cbd1e2;
  border-radius: 5px;
  padding: 35px 60px;
  background: #ffffff;
  z-index: 2;
  ${BreakpointDown.md`
      padding:25px 25px;
   `}
`;
const SlickSlider = styled.div`
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 40px;

  &:before {
    content: "";
    background: #ffffff;
    border: 1px solid #cbd1e2;
    border-radius: 5px;
    position: absolute;
    bottom: -20px;
    top: 0;
    left: 60px;
    right: 60px;
    opacity: 0.7;
    z-index: 1;
  }
  &:after {
    content: "";
    background: #ffffff;
    border: 1px solid #cbd1e2;
    border-radius: 5px;
    position: absolute;
    bottom: -40px;
    top: 0;
    left: 80px;
    right: 80px;
    opacity: 0.4;
  }
  .slick-slider {
    .slick-arrow {
      display: none !important;
    }
  }
`;
const SlickItem = styled.div`
  display: block !important;
  outline: none;
  max-width: 100%;
  font-size: 18px;
  text-align: center;
  p {
    font-style: italic;
    margin-top:10px;
  }
`;
const SlickArrowTrack = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
const LeftArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  box-shadow: 0px 10px 25px #143da733;
  border-radius: 5px;
  background-color: #223c7e;
  left: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  @media (hover: hover) {
    &:hover {
      left: -22px;
    }
  }
  cursor: pointer;
  ${BreakpointDown.lg`
     left: 5px;
    `}
  ${BreakpointDown.md`
        left: -12px;
    `}
  svg {
    fill: #fff;
    width: 16px;
    height: 12px;
    transform: rotate(180deg);
  }
`;
const RightArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  box-shadow: 0px 10px 25px #143da733;
  border-radius: 5px;
  background-color: #223c7e;
  right: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  @media (hover: hover) {
    &:hover {
      right: -22px;
    }
  }
  cursor: pointer;
  ${BreakpointDown.lg`
     right: 5px;
    `}
  ${BreakpointDown.md`
        right: -12px;
    `}
  svg {
    fill: #fff;
    width: 16px;
    height: 12px;
    transform: rotate(0deg);
  }
`;
const ReviewAuther = styled.div`
  font-weight: bold;
  font-size: 20px;
`;
const AutherLocation = styled.div`
  font-size: 18px;
  line-height: 18px;
`;
const QuoteSign = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -28px;
  width: 50px;
  height: 50px;
  background: #f36224;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  color: #fff;
  line-height: 41px;
  font-size: 58px;
  &:before {
    content: "❝";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    height: 41px;
  }
`;

function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <ArrowIcon />
    </button>
  );
}
function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <ArrowIcon />
    </button>
  );
}
export default class CustomerReviewCarousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      slidesToShow: 1,
      infinite: true,
      nextArrow: <CircleNextArrow />,
      prevArrow: <CirclePrevArrow />,
      arrow: false,
      speed: 400,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows: false,
            centerMode: true,
            centerPadding: "0",
            infinite: true,
          },
        },
      ],
    };
    return (
      <SectionBorderTop
        bgColor="#fff"
        pt="100px"
        pb="150px"
        xpt="40px"
        xpb="80px"
        bgBefore={`url(${LeftBg})`}
        bgAfter={`url(${RightBg})`}
      >
        <div className="container">
          <SectionTitle maxWidth="880px">
            What Our Customers and Dealers Say About Us
          </SectionTitle>
          <SlickSlider>
            <QuoteSign />
            <SlickSliderInner>
              {this.props.rtoReviews.nodes.length > 1 ? (
                <SlickArrowTrack>
                  <LeftArrow onClick={this.previous}>
                    <ArrowLineIcon />
                  </LeftArrow>
                  <RightArrow onClick={this.next}>
                    <ArrowLineIcon />
                  </RightArrow>
                </SlickArrowTrack>
              ):null
  }
              <Slider {...settings} ref={(c) => (this.slider = c)}>
                {this.props.rtoReviews.nodes.map((item) => {
                  return (
                    <SlickItem>
                      <ReviewAuther>{item.headingText}</ReviewAuther>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.reviewText.childMarkdownRemark.html,
                        }}
                      />
                      <ReviewAuther>{item.fullName}</ReviewAuther>
                      <AutherLocation>{item.cityAndState}</AutherLocation>
                    </SlickItem>
                  );
                })}
              </Slider>
            </SlickSliderInner>
          </SlickSlider>
        </div>
      </SectionBorderTop>
    );
  }
}
