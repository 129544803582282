import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link, graphql } from "gatsby";
import styled from "styled-components";

import { HomeIcon, ArrowRight, CloseIcon } from "../components/Icons";
import {
  Section,
  BreadCrumb,
  SectionPageTitle,
  GradientWrapper,
  MarkdownContent,
  MarkdownContentWrapper,
  MarkdownContentWrapperInner,
  Modal,
  CloseButton,
  ModalDialog,
  ModalContent,
  ModalBody,
  Overlay,
} from "../components/Section";
import BreakpointDown from "../components/Media/BreakpointDown";
import SecondaryLinkButton from "../components/Button/SecondaryLinkButton";
import { ArrowLineIcon } from "../components/Icons";
import ContractSlider from "../sections/rent-to-own/contract-slider";
import PaymentCalulator from "../components/PaymentCalculator";
import BgAfter from "../images/pattenr-right.png";
import BgBefore from "../images/bg-left.png";
import CustomerReviewCarousel from "../sections/rent-to-own/customer-review-slider";
import checkBlue from "../images/check-blue.svg";

const GetSatartedWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
`;
const GetSatarted = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  align-items: flex-end;
  ${BreakpointDown.xl`
     align-items:flex-start;
   `}
  ${BreakpointDown.lg`
     flex-direction:column;
     margin-top:20px;
   `}
`;
const GetSatartedLeft = styled.div`
  width: 100%;
  max-width: 60%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  ${BreakpointDown.xl`
    max-width:50%;
  `}
  ${BreakpointDown.lg`
      max-width:100%;
   `}
`;
const GetSatartedRight = styled.div`
  width: 100%;
  max-width: 40%;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: 86px;
  margin-bottom: -180px;
  ${BreakpointDown.xl`
    padding-left:15px;
    padding-right:15px; 
    max-width:50%;
    margin-left:auto;
    margin-right:auto;
  `}
  ${BreakpointDown.lg`
      max-width:100%;
      padding-left:15px;
   `}
   ${BreakpointDown.md`
    margin-bottom:-107px;
   `}
`;

// WaiverCardWrapper
const WaiverCardWrapper = styled.div`
  position: absolute;
  border-top: 2px #cbd1e2 dashed;
  padding-top: 30px;
  margin-top: 28px;
  z-index: 2;
  ${BreakpointDown.lg`
      position:static;
  `}
`;
const WaiverCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 50px #0000000f;
  border: 1px solid #cbd1e2;
  border-radius: 5px;
  position: relative;
  padding: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  height: 100%;
  @media (max-width: 1440px) {
    padding: 20px;
  }
  ${BreakpointDown.xl`
    padding:15px;
  `}

  &:before {
    position: absolute;
    right: 5px;
    top: 5px;
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid #ebf0ff;
    border-right: 20px solid #ebf0ff;
    border-bottom: 20px solid transparent;
    border-left: 20px solid transparent;
  }
`;
const WaiverCardText = styled.div`
  font-size: 16px;
  line-height: 28px;
  span {
    &:first-child {
      letter-spacing: 0.16px;
    }
    padding-top: 0px;
    padding-bottom: 0;
  }
`;
const WaiverCardTtile = styled.div`
  color: #121316;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 8px;
  width: 100%;

  @media (max-width: 1440px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const SectionPageTitleHeading = styled(SectionPageTitle)`
  @media (min-width: 1280px) {
    font-size: 42px;
  }
`;

function RentToOwnPage(queryData) {
  let pageData = queryData.data.contentfulRtoProgramPage;
  let rtoReviews = queryData.data.allContentfulReview;
  let calculatorData =
    queryData.data.allContentfulMonthlyCalculatorValues.nodes[0];

  const [isVisibleQuote, setisVisibleQuote] = useState(false);

  return (
    <Layout pageName="rto-programs">
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <Section
        bgColor="#EBF0FF"
        pt="180px"
        pb="100px"
        xpt="80px"
        xpb="120px"
        mpb="60px"
      >
        <BreadCrumb xtop="10px">
          <div className="container">
            <Link to="/">
              <HomeIcon />
              <ArrowRight />
              </Link>
              <Link className="line-after-heading">
              Rent To Own
            </Link>
          </div>
        </BreadCrumb>
        <GetSatartedWrapper>
          <div className="container">
            <GetSatarted>
              <GetSatartedLeft>
                <SectionPageTitleHeading
                  textAlign="left"
                  ml="0"
                  mb="10px"
                  maxWidth="580px"
                >
                  {pageData.rtoFirstSection.headingText}
                </SectionPageTitleHeading>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      pageData.rtoFirstSection.contentText.childMarkdownRemark
                        .html,
                  }}
                />
                <WaiverCardWrapper>
                  <WaiverCard>
                    <WaiverCardTtile>
                      {pageData.ldwSection.subHeading}
                    </WaiverCardTtile>
                    <WaiverCardText>
                      <span>{pageData.ldwSection.headingText}</span>
                      <button onClick={(e) => setisVisibleQuote(true)}>
                        <SecondaryLinkButton
                          text="Know More"
                          icon={<ArrowLineIcon />}
                        />
                      </button>
                    </WaiverCardText>
                  </WaiverCard>
                </WaiverCardWrapper>
              </GetSatartedLeft>
              <GetSatartedRight>
                <ContractSlider features={pageData.rtoFirstSection.features} />
              </GetSatartedRight>
            </GetSatarted>
          </div>
        </GetSatartedWrapper>
      </Section>
      <Section
        bgColor="#fff"
        pt="160px"
        pb="200px"
        xpt="150px"
        xpb="60px"
        mpt="40px"
        mpb="40px"
        bgBefore={`url(${BgBefore})`}
        bgAfter={`url(${BgAfter})`}
      >
        <PaymentCalulator calculatorData={calculatorData} />
      </Section>
      <CustomerReviewCarousel rtoReviews={rtoReviews} />
      <GradientWrapper>
        <Section
          pt="100px"
          pb="177px"
          xpt="60px"
          xpb="100px"
          mpt="40px"
          mpb="100px"
          bdrTop="#CBD1E2"
          bgColor="transparent"
        >
          <div className="container">
            <MarkdownContent bg={checkBlue}>
              <MarkdownContentWrapper>
                <MarkdownContentWrapperInner>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.contentSection.childMarkdownRemark.html,
                    }}
                  />
                </MarkdownContentWrapperInner>
              </MarkdownContentWrapper>
            </MarkdownContent>
          </div>
        </Section>
      </GradientWrapper>
      <Modal
        className={"modal fade " + (isVisibleQuote ? "show" : "")}
        style={{ display: isVisibleQuote ? "block" : "none" }}
      >
        <ModalDialog className="modal-dialog modal-lg modal-dialog-centered">
          <ModalContent className="modal-content">
            <ModalBody className="modal-body p-0">
              <CloseButton
                className="close"
                onClick={() => setisVisibleQuote(false)}
              >
                <CloseIcon />
              </CloseButton>
              <MarkdownContentWrapperInner
                style={{ height: "70vh", overflowY: "scroll" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      pageData.ldwSection.contentText.childMarkdownRemark.html,
                  }}
                />
              </MarkdownContentWrapperInner>
            </ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
      {isVisibleQuote && <Overlay />}
    </Layout>
  );
}

export default RentToOwnPage;

export const query = graphql`
  query RtoProgramPage {
    contentfulRtoProgramPage {
      pageUrl
      metaTitle
      metaDescription {
        metaDescription
      }
      rtoFirstSection {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        features {
          titleText
          descriptionText {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            fluid {
              aspectRatio
              base64
              src
              srcWebp
              srcSet
              srcSetWebp
            }
          }
          buttonText
        }
      }
      ldwSection {
        subHeading
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
      }
      contentSection {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulReview(filter: { reviewType: { eq: "RTO Review" } }) {
      nodes {
        headingText
        reviewText {
          childMarkdownRemark {
            html
          }
        }
        fullName
        cityAndState
        reviewType
      }
    }
    allContentfulMonthlyCalculatorValues(filter: { active: { eq: true } }) {
      nodes {
        option1NumberOfMonths
        option1Divisor
        option2NumberOfMonths
        option2Divisor
        option3NumberOfMonths
        option3Divisor
        option4NumberOfMonths
        option4Divisor
      }
    }
  }
`;
