import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowIcon, ArrowLineIcon } from "../../components/Icons";
import CheckOrangeImg from "../../images/check-orange.svg";
import BreakpointDown from "../../components/Media/BreakpointDown";
import Img from "gatsby-image";


const SliderWrapper = styled.div`
  margin-left: -50px;
  margin-right: -1px;
  margin-top: 20px;
  padding-right:30px;
  position: relative;
  border-radius: 5px 0 0 5px;
  margin-bottom:-50px;
  z-index:4;
  @media (max-width: 1366px) {
    margin-left: -52px;
    padding-right:0px;
  }
  ${BreakpointDown.lg`
   margin-left:0;
  `}
  ${BreakpointDown.md`
      padding:5px 0px;
   `}
`;
const SlickSlider = styled.div`
  position: relative;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  .slick-slider {
    .slick-arrow {
      display: none !important;
    }
  }
`;
const SlickItem = styled.div`
  display: block !important;
  outline: none;
  max-width: 100%;
  font-size: 18px;
  ul {
    margin-left: -47px;
    background: #fff;
    box-shadow: 0px 10px 50px rgba(208, 208, 208, .60);
    border: 1px solid #CBD1E2;
    border-radius: 5px 0px 0px 5px;
    padding: 0px 20px 0 35px; 
    border-right: 0;
    ${BreakpointDown.md`
      margin-left: 0px;
      border-right: 1px solid #cbd1e2;
    `}
    li {
      &:before {
        background: url(${CheckOrangeImg});
      }
    }
  }

`;
const SlickArrowTrack = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 40px;
`;
const LeftArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  box-shadow: 0px 10px 25px #143da733;
  border-radius: 5px;
  background-color: #223c7e;
  left: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  ${BreakpointDown.lg`
    left: 74px;
  `}
  &:hover {
    margin-left:-2px; 
  }
  cursor: pointer;
  ${BreakpointDown.md`
    left: 6px;
  `}
  svg {
    fill: #fff;
    width: 16px;
    height: 12px;
    transform: rotate(180deg);
  }
`;
const RightArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  box-shadow: 0px 10px 25px #143da733;
  border-radius: 5px;
  background-color: #223c7e;
  right: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s all;
  @media(max-width:1366px){
    right: 48px;
  }
  ${BreakpointDown.lg`
     right: 104px;
  `}
  ${BreakpointDown.md`
      right: 6px;
  `}
  svg {
    fill: #fff;
    width: 16px;
    height: 12px;
    transform: rotate(0deg);
  }
  &:hover {
   margin-right:-2px; 
   
  }
`;
//Contract Chart
const ContractCard = styled.div`
  background: #ffffff;
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: relative;
  border-radius: 5px;
  margin-top: 28px;
  margin-left: 40px;
  margin-right: 64px;
  margin-bottom: 44px;
  ${BreakpointDown.lg`
      max-width:500px;
      margin-left:auto;
      margin-right:auto;
  `}

  &:before {
    content: "";
      display: block;
      box-shadow: 0px 10px 50px #0000000f;
      border: 1px solid #cbd1e2;
      position: absolute;
      background: rgba(255, 255, 255, 0.99);
      right: -30px;
      top: 0;
      left: 0;
      bottom: 20px;
      z-index: 1;
    ${BreakpointDown.xl`
        display:none;
    `}
  }
  &:after {
    content: "";
    display: block;
    box-shadow: 0px 10px 50px #0000000f;
    border: 1px solid #cbd1e2;
    position: absolute;
    right: -45px;
    background: rgba(255, 255, 255, 0.1);
    top: 41px;
    left: 0;
    z-index: 1;
    bottom: 36px;
    ${BreakpointDown.xl`
        display:none;
    `}
  }
`;
const ContractCardTitle = styled.div`
  padding: 20px;
  background: #121316;
  color: #fff;
  width: 100%;
  font-size: 26px;
  font-weight: 600;
  position: relative;
  border-radius: 5px 5px 0 0;
  z-index: 4;
  ${BreakpointDown.md`
      font-size:20px;
      line-height: 31px;
    `}

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-top: 8px solid #121316;
    border-right: 16px solid transparent;
    border-bottom: 16px solid transparent;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    left: 50%;
    z-index: 2;
  }
`;
const TitlleImg = styled.div`
  position: absolute;
  max-width: 138px;
  width: 100%;
  top: -33px;
  right: -60px;
  z-index: 2;
  ${BreakpointDown.xl`
    right: -8px;
    top: -12px;
    max-width: 88px;
 `}
`;

const ContractCardBody = styled.div`
  padding: 20px 0 50px 20px;
  position: relative;
  background: #fff;
  border-radius: 0 0 5px 5px;
  z-index: 3;
  box-shadow: 0px 10px 50px #0000000f;
  border: 1px solid #cbd1e2;
  border-radius: 5px;
  ${BreakpointDown.xl`
    padding: 15px 0 15px 15px;
  `}
   ${BreakpointDown.md`
    padding: 15px 15px 15px 15px;
  `}
`;
const OwnershipText = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  line-height: 40px;
  max-width: 300px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-transform: capitalize;
  @media(max-width:1366px){
    margin-top: 6px;
  margin-bottom: 6px;
  }
  ${BreakpointDown.md`
    margin-top:0px;
    margin-bottom:0px;
  `}
   ${BreakpointDown.sm`
    font-size: 20px;
    line-height:28px;
  `}
  strong {
    font-size: 28px;
  }
`;

function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <ArrowIcon />
    </button>
  );
}
function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <ArrowIcon />
    </button>
  );
}
export default class ContractSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      slidesToShow: 1,
      infinite: true,
      nextArrow: <CircleNextArrow />,
      prevArrow: <CirclePrevArrow />,
      arrow: false,
      speed: 500,

      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows: false,
            centerMode: true,
            centerPadding: "0",
            infinite: true,
          },
        },
      ],
    };

    return (
      <SliderWrapper>
        {this.props.features.length > 1 ? (
          <SlickArrowTrack>
            <LeftArrow onClick={this.previous}>
              <ArrowLineIcon />
            </LeftArrow>
            <RightArrow onClick={this.next}>
              <ArrowLineIcon />
            </RightArrow>
          </SlickArrowTrack>
        ):null
      }
        <SlickSlider>
          <Slider {...settings} ref={(c) => (this.slider = c)}>
            {this.props.features.map((item) => {
              return (
                <SlickItem>
                  <ContractCard>
                    <ContractCardTitle>
                      {item.titleText}
                      <TitlleImg>
                        <Img fluid={item.image.fluid} alt={item.image.title} />
                      </TitlleImg>
                    </ContractCardTitle>
                    <ContractCardBody>
                      <OwnershipText>{item.buttonText}</OwnershipText>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.descriptionText.childMarkdownRemark.html,
                        }}
                      />
                    </ContractCardBody>
                  </ContractCard>
                </SlickItem>
              );
            })}
          </Slider>
        </SlickSlider>
      </SliderWrapper>
    );
  }
}
