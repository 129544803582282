import React, { useState, useCallback, useEffect } from "react";
import { SectionTitle } from "../../components/Section";
import styled from "styled-components";
import ArroDownImg from "../../images/arrow-down.svg";

import BreakpointDown from "../../components/Media/BreakpointDown";

const PaymentCalulatorWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const PaymentCalulatorItem = styled.div`
  color: #868da2;
  font-size: 20px;
  flex: 1;
  &:first-child {
    margin-right: 22px;
    @media (max-width: 1200px) {
      margin-right: 12px;
    }
  }
  &:nth-child(2) {
    margin-left: 22px;
    margin-right: 22px;
    @media (max-width: 1200px) {
      margin-left: 12px;
      margin-right: 12px;
    }
    ${BreakpointDown.lg`
     margin-right:0;
  `}
    ${BreakpointDown.sm`
    margin-left:0px;
    margin-top:15px;
    flex: 1 1 100%;

  `}
  }

  &:nth-child(3) {
    margin-left: 22px;
    margin-right: 22px;
    @media (max-width: 1200px) {
      margin-left: 12px;
      margin-right: 12px;
    }
    ${BreakpointDown.lg`
    margin-top:20px;
    width:100%;
    flex: 0 0 100%; 
    margin-left:0;
    margin-right:0;
  `}
  }

  select {
    height: 75px;
    border: 0;
    border: 1px solid #cbd1e2;
    border-radius: 5px;
    background: #fff;
    color: #868da2;
    padding: 20px 30px;
    width: 100%;
    appearance: none;
    position: relative;
    background: #fff url(${ArroDownImg}) no-repeat;
    background-position: 95% 50%;
    &:focus {
      border-color: #cbd1e2;
      outline: none;
    }
  }

  input[type="text"] {
    height: 75px;
    border: 0;
    border: 1px solid #cbd1e2;
    border-radius: 5px;
    background: #fff;
    color: #868da2;
    padding: 20px 30px;
    width: 100%;
    ${BreakpointDown.sm`
     height:48px;
    `}
    &:focus {
      border-color: #cbd1e2;
      outline: none;
    }
  }
`;

const PaymentCalulatorTittle = styled.div`
  font-size: 20px;
  color: #121316;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
`;

const RadioGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -4px;
  margin-right: -4px;
  label {
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 4px;
    margin-right: 4px;

    @media (min-width: 768px) {
      min-width: 92px;
    }
    span {
      padding: 20px;
      width: 100%;
      text-align: center;
      ${BreakpointDown.sm`
     padding:10px 20px; 
    `}
    }
    cursor: pointer;
    input[type="radio"] {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      cursor: pointer;
      &:checked + span {
        color: #f36224;
        box-shadow: 0px 5px 15px #ff4c001a;
        display: block;
      }
    }
  }
`;
const MonthlyPayment = styled.div`
  color: #fff;
  background: #223c7e;
  border-radius: 5px;
  width: 100%;
  padding: 26px 26px 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  font-family: "Poppins", sans-serif;
  ${BreakpointDown.lg`
        padding: 16px 16px 15px;
    `}
  .payment-title {
    font-size: 24px;
    line-height: 35px;
    margin-right: 10px;
    font-weight: 600;

    @media (min-width: 992px) {
      max-width: 124px;
    }
  }
  .payment-price {
    line-height: 70px;
    font-size: 50px;
    font-weight: 600;
    @media (max-width: 1200px) {
      font-size: 30px;
      line-height: 50px;
    }
  }
  .btn {
    position: absolute;
    bottom: -23px;
  }
`;

const MonthlyPaymentItem = styled.div`
  display: flex;
  align-items: center;
`;

function PaymentCalulator({ calculatorData }) {
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [term, setTerm] = useState(calculatorData.option1NumberOfMonths);
  const [loan, setLoan] = useState(0);

  const monthlyPyamentCallback = useCallback(
    (e) => {
      const { value } = e.target;
      setLoan(value);
      if (term === calculatorData.option1NumberOfMonths) {
        var price = (value / calculatorData.option1Divisor).toFixed(2);
        setMonthlyPayment(price);
      }
      if (term === calculatorData.option2NumberOfMonths) {
        price = (value / calculatorData.option2Divisor).toFixed(2);
        setMonthlyPayment(price);
      }
      if (term === calculatorData.option3NumberOfMonths) {
        price = (value / calculatorData.option3Divisor).toFixed(2);
        setMonthlyPayment(price);
      }
      if (term === calculatorData.option4NumberOfMonths) {
        price = (value / calculatorData.option4Divisor).toFixed(2);
        setMonthlyPayment(price);
      }
    },
    [monthlyPayment, setMonthlyPayment]
  );

  useEffect(() => {
    if (term === calculatorData.option1NumberOfMonths) {
      var value = (loan / calculatorData.option1Divisor).toFixed(2);
      setMonthlyPayment(value);
    }
    if (term === calculatorData.option2NumberOfMonths) {
      value = (loan / calculatorData.option2Divisor).toFixed(2);
      setMonthlyPayment(value);
    }
    if (term === calculatorData.option3NumberOfMonths) {
      value = (loan / calculatorData.option3Divisor).toFixed(2);
      setMonthlyPayment(value);
    }
    if (term === calculatorData.option4NumberOfMonths) {
      value = (loan / calculatorData.option4Divisor).toFixed(2);
      setMonthlyPayment(value);
    }
  });

  return (
    <div className="container container-zindex-up">
      <SectionTitle
        textAlign="left"
        mb="8px"
        lgTextAlign="center"
        mdTextAlign="left"
      >
        Calculate Your Monthly Payment
      </SectionTitle>
      <PaymentCalulatorWrapper>
        <PaymentCalulatorItem>
          <PaymentCalulatorTittle>Term (in months)</PaymentCalulatorTittle>
          <RadioGroupWrapper id="term_month">
            <label htmlFor="60">
              <input
                id="60"
                type="radio"
                value="60"
                name="term_month"
                onClick={() => setTerm(calculatorData.option1NumberOfMonths)}
                defaultChecked
              />
              <span>{calculatorData.option1NumberOfMonths}</span>
            </label>
            <label htmlFor="radio-2">
              <input
                id="radio-2"
                value="120"
                type="radio"
                name="term_month"
                onClick={() => setTerm(calculatorData.option2NumberOfMonths)}
              />
              <span>{calculatorData.option2NumberOfMonths}</span>
            </label>
            <label htmlFor="180">
              <input
                id="180"
                type="radio"
                name="term_month"
                onClick={() => setTerm(calculatorData.option3NumberOfMonths)}
              />
              <span>{calculatorData.option3NumberOfMonths}</span>
            </label>
            <label htmlFor="180">
              <input
                id="180"
                type="radio"
                name="term_month"
                onClick={() => setTerm(calculatorData.option4NumberOfMonths)}
              />
              <span>{calculatorData.option4NumberOfMonths}</span>
            </label>
          </RadioGroupWrapper>
        </PaymentCalulatorItem>
        <PaymentCalulatorItem>
          <PaymentCalulatorTittle>Enter Loan Amount</PaymentCalulatorTittle>
          <input
            type="text"
            maxlength="8"
            id="loan_amount"
            placeholder="Enter Loan Amount"
            onChange={monthlyPyamentCallback}
          />
        </PaymentCalulatorItem>
        <PaymentCalulatorItem>
          <MonthlyPayment>
            <MonthlyPaymentItem>
              <div className="payment-title">Monthly Payment</div>
              <div className="payment-price">${monthlyPayment}</div>
            </MonthlyPaymentItem>
          </MonthlyPayment>
        </PaymentCalulatorItem>
      </PaymentCalulatorWrapper>
    </div>
  );
}

export default PaymentCalulator;
